if(module.hot) {
    module.hot.accept();
}

import 'selectize';
import {
    jarallax,
    jarallaxElement,
    jarallaxVideo
} from 'jarallax';

jarallaxVideo();
jarallaxElement();

const body = $('body');
const header = $('header.header');
const topBar  = $('.top-bar');
const wptheme = jQuery('input#WPtheme').val();

let winOS = true;
let OSName = 'WinOS';

$(document).ready(function() {
    body.removeClass("preload");
});


$('.wp-block-cover').each(function() {
    if( $(this).find('a').length ) {
        $(this).addClass('has-link');
        let urlVal = $(this).find('a').attr('href');
        $(this).append('<a href="' + urlVal + '" class="full-link"></a>')
    }
});



//////////////////////
//   Init
//////////////////////
openCloseMenu();
goToBottomHeader();
goToTop();
scrollPage();


////////////////////
//   Google Map
////////////////////
if( body.hasClass('page-template-template-contact') ) {

    //var gstyles = [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#000000"},{"lightness":40}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#000000"},{"lightness":16}]},{"featureType":"all","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#000000"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#000000"},{"lightness":17},{"weight":1.2}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#463e49"},{"lightness":"0"},{"gamma":"1"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#413944"},{"lightness":"0"},{"gamma":"1"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#000000"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#000000"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#514954"},{"lightness":"0"},{"gamma":"1"},{"weight":"2.5"}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#514954"},{"lightness":"0"},{"gamma":"1"},{"weight":"0.7"}]},{"featureType":"road.local","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"lightness":"-60"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#372f3a"},{"lightness":"1"},{"gamma":"1"}]}];

    var address_1 = jQuery('input#adresse-1').val();
    var address_2 = jQuery('input#adresse-2').val();

    // console.log(address_1);
    // console.log(address_2);

    // When the window has finished loading create our google map below
    google.maps.event.addDomListener(window, 'load', init);

    function init() {
        // Basic options for a simple Google Map
        // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
        var mapOptions = {
            // How zoomed in you want the map to start at (always required)
            zoom: 16,
            scrollwheel: false,
            // The latitude and longitude to center the map (always required)
            //center: new google.maps.LatLng(48.058632, -2.969668),
            disableDefaultUI: true,

            // How you would like to style the map.
            // This is where you would paste any style found on Snazzy Maps.
            //styles: gstyles
        };

        // Get the HTML DOM element that will contain your map
        // We are using a div with id="map" seen below in the <body>
        var mapElement = document.getElementById('gmap');

        // Create the Google Map using our element and options defined above
        var map = new google.maps.Map(mapElement, mapOptions);

        var bounds = new google.maps.LatLngBounds();

        var addresses = [
            address_1,
            address_2
        ];

        function geocodeAddress(address) {
            let geocoder = new google.maps.Geocoder();
            console.log(address);
            geocoder.geocode({'address': address}, function (results, status) {
                if (status == google.maps.GeocoderStatus.OK) {
                    //map.setCenter(results[0].geometry.location);
                    var marker = new google.maps.Marker({
                        map: map,
                        position: results[0].geometry.location,
                        icon: '/wp-content/themes/' + wptheme + '/assets/images/marker.png'
                    });
                }
                bounds.extend(marker.position);
                map.fitBounds(bounds);
            });
        }

        for ( let i = 0; i < addresses.length; i++) {
            geocodeAddress(addresses[i]);
        }
    }

}


//////////////////////
//   Hauteur Fold
//////////////////////
$('.home header.header .bgHeader').css( 'height', getWindowHeight() );



$(window).on('resize', function(){
    $(body).removeClass('opened-menu').addClass('closed-menu');
});


$('select#fld_9895441_1 option[value$="optgroup_open"]').each(function(){
    let optGroupLabel = $(this).text();
    let optGroupClose = $(this).nextAll('option[value$="optgroup_close"]').first();
    let optionsInGroup = $(this).nextUntil(optGroupClose);
    optionsInGroup.wrapAll("<optgroup label='"+optGroupLabel+"'></optgroup>")
    optGroupClose.remove();
    $(this).remove();
});

//////////////////////
//   Selectize
//////////////////////
let $filterMetiers = jQuery('select#filterMetiers').selectize({
    maxItems: 1,
    closeAfterSelect: true,
    sortField: {
        field: 'text',
        direction: 'asc'
    },
});


$('.liste-temoignages .card-temoignage').click(function(){
    var videoID = $(this).attr('data-video_id');
    //console.log("videoID : "+videoID);
    var iframeSrcVideo = 'https://www.youtube.com/embed/'+videoID+'?autoplay=1&rel=0&showinfo=0';
    // jQuery('html, body').animate({
    //     scrollTop: jQuery("#myplayer").offset().top
    // }, 500, function(){
    //     jQuery('#myplayer iframe').attr('src',iframeSrcVideo);
    // });
    jQuery('#myplayer iframe').attr('src',iframeSrcVideo);

});


//////////////////////////
//   Parallax
//////////////////////////
if( getWindowWidth() >= 728 ) {
    jarallax(document.querySelectorAll('.wp-block-cover.alignfull'), {
        speed: 0.7
    });
}



//////////////////////
//   FUNCTIONS
//////////////////////
function openCloseMenu(winOS) {
    body.on('click', '.burger, .close, .__overlay-main-menu', function(e){
        if( ! body.is('.opened-menu')){
            $(body).removeClass('closed-menu').addClass('opened-menu');
        } else {
            $(body).removeClass('opened-menu').addClass('closed-menu');
        }
    } );
}

function goToTop() {
    $('.goToTop').on("click", function (e) {
        e.preventDefault();
        $('html, body').animate({scrollTop: 0}, 'slow');
    });
}

function goToBottomHeader() {
    $('.bloc-rejoindre .full-link').on("click", function (e) {
        e.preventDefault();
        $('html, body').animate({scrollTop: getWindowHeight()}, 1000);
    });
}

function scrollPage() {
    $(window).scroll(function () {
        let st = $(this).scrollTop();
        if ( st >= 250) {
            topBar.addClass('fixed-pos');
        } else {
            topBar.removeClass('fixed-pos');
        }
    });
}

function getWindowWidth(windowWidth) {
    windowWidth = 0;
    if (typeof(window.innerWidth) === 'number') {
        windowWidth = window.innerWidth;
    }
    else {
        if (document.documentElement && document.documentElement.clientWidth) {
            windowWidth = document.documentElement.clientWidth;
        }
        else {
            if (document.body && document.body.clientWidth) {
                windowWidth = document.body.clientWidth;
            }
        }
    }
    return windowWidth;
}

function getWindowHeight() {
    var windowHeight = 0;
    if (typeof(window.innerHeight) === 'number') {
        windowHeight = window.innerHeight;
    }
    else {
        if (document.documentElement && document.documentElement.clientHeight) {
            windowHeight = document.documentElement.clientHeight;
        }
        else {
            if (document.body && document.body.clientHeight) {
                windowHeight = document.body.clientHeight;
            }
        }
    }
    return windowHeight;
}
